body {
  font-weight: 500;
  background-color: #edeff0;
}

.App {
  text-align: center;
}

#app-container {
  background-color: #f7f7f7;
  min-width: 680px;
  position: relative;
  min-height: 100vh;
}

.form-input-container {
  height: 44px;
}

a .MuiButton-root {
  text-transform: none;
}