#homepage-body {
    padding: 10px;
    width: 100%;
    max-height: 480px;
    min-width: 670px;
    margin-bottom: 2.75rem;
    transform: translateY(50%);
}

#home-paper-grid #home-paper {
    width: 100%;
}

.home-icon {
    height: 100px;
}


#home-cod-outbound #home-cod-inbound {
    padding-top: 10%;
}

#home-outbound {
    padding: 2%;
    max-width: 120px;
}

#home-inbound {
    padding: 2%;
    max-width: 120px;
}

#home-cod-inbound {
    display: flex;
    justify-content: center
}

#home-cod-outbound {
    display: flex;
    justify-content: center
}