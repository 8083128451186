#home-body {
    padding: 10px;
    width: 100%;
    min-width: 670px;
    margin-bottom: 2.75rem;
}

#home-paper-grid #home-paper {
    width: 100%;
}

#home-form-header {
    padding: 16px;
    height: 80px;
}

#home-form-header-icon {
    margin-right: 0.75rem;
}

#home-form-body {
    padding: 16px;
}

#home-cod-logo-item {
    padding-top: 20px;
}

#home-cod-logo {
    max-width: 390px;
}

@media screen and (max-width: 1050px) {
    #home-cod-logo-item {
        display: none;
    }
}

#cod-form-container {
    background-color: white;
}

.cod-form-input-container {
    height: 44px;
    margin-bottom: 5px;
}

.cod-form-input {
    width: 100%;
    margin-top: 4px;
    margin-bottom: 4px;
}

#customize-btn {
    margin-top: 2rem;
    min-width: 1rem;
    width: 40rem;
    height: 36px;
    border-radius: 4px;
    background-color: #0a6ed1;
    color: white;
    font-weight: bold;
}

#tune-icon {
    padding-right: 5px;
}

input {
    padding: 0px 10px 0px 10px;
    border: 1px solid #8f8f9d;
    border-radius: 2px;
}

#cod-form-container input[type=text]:hover {
    border: 1px solid #1976d2;
    border-radius: 2px;
}

/* input:invalid {
    border: 1px solid red;
} */

.star-required {
    color: red;
}

#dialog-progress {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 15px;
}