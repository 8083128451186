#nav-bar-container {
    height: 44px;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    border-bottom: solid 1px #d9d9d9;
    background-color: white;
    position: sticky;
    top: 44px;
    z-index: 1;
}

#nav-bar-container .MuiSvgIcon-root {
    padding-right: 4px;
}

#show-profile-btn:hover {
    border: 1px solid #0854a0;
}

#nav-user-item {
    color: #0854a0;
    font-size: small;
}

#nav-title-item {
    display: flex;
    justify-content: center;
}

#nav-btn-item {
    display: flex;
    justify-content: flex-end;
}

.link-btns {
    text-decoration: none;
}

#nav-btn {
    border: 1px solid transparent;
    transition-duration: 0.2s;
    color: #0b56a1;
    max-height: 38px;
}

#nav-btn:hover {
    border: 1px solid #0854a0;
}