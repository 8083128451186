#header-container {
    height: 44px;
    background-color: white;
    border-bottom: solid 1px #d9d9d9;
    position: sticky;
    top: 0;
    z-index: 1;
}

#header-bmx-logo {
    height: 80%;
    margin-right: 0.5rem;
}