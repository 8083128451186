#printings-body {
    width: 100%;
    height: 100%;
    margin-bottom: 2.75rem;
}

#printings-summary {
    padding-top: 10px;
    background-color: white;
    width: 100%;
    border-bottom: solid 1px #d9d9d9;
    padding-bottom: 10px;
    padding-left: 25px;
}

#printings-summary h3 {
    margin-top: 5px;
    margin-left: 0px;
}

#summary-info {
    width: 100%;
    color: #6a6d70;
    margin-bottom: 10px;
}

.summary-item {
    margin-right: 32px;
}

.summary-label {
    font-weight: 600;
    padding-bottom: 10px;
}

#documents-body {
    padding-left: 25px;
    padding-top: 10px;
    width: 100%;
    height: 100%;
    padding-bottom: 20px;
}

#documents-body h3 {
    margin-top: 5px;
    margin-left: 0px;
}

#nb-copies {
    max-width: 50px;
    border-radius: 4px;
}

#table-container {
    width: 100%;
    padding-right: 30px;
    min-width: 1035px;
}

#table-header {
    background-color: #f2f2f2;
}

.MuiSvgIcon-root {
    padding-right: 4px;
}

#total-prints-container {
    display: flex;
    height: 34px;
    margin-top: 10px;
    margin-bottom: 20px;
}

#set-prints-btn {
    margin-left: 5px;
}

.format-logos {
    width: 50px;
    padding-top: 5px;
}

.disabled-row {
    background-color: rgb(211, 211, 211);
}

.disabled-row td {
    color: rgb(158, 158, 158);
}

.disabled-row img {
    filter: opacity(30%);
}