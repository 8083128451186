#audit-trail-body {
    width: 100%;
    height: 100%;
    padding: 20px;
    padding-top: 5px;
    margin-bottom: 2rem;
}

#audit-trail-header {
    padding-left: 10px;
    padding-right: 10px;
    color: #6a6d70;
    font-size: .75rem;
    height: 76px;
    max-width: max-content;
    cursor: pointer;
}

#header-divider {
    margin-right: 22px;
    height: 40px;
}

#clear-dates-btn {
    margin-right: -8px;
    margin-left: -30px;
}

.react-datepicker__triangle {
    visibility: hidden;
}

.date-picker-wrapper {
    max-width: fit-content;
}

#datepicker {
    height: 34px;
    width: 185px;
    border-radius: 1px;
    border-color: #c4c4c4;
}

.form-datepicker {
    width: 96.5%;
    height: 34px;
}

.react-datepicker__tab-loop {
    z-index: 3;
}

#all-ops {
    height: 100%;
    display: flex;
    align-items: center;
    margin-right: 22px;
    margin-top: 15px;
}

#all-ops-nb {
    font-size: 1.5rem;
    margin-bottom: 8px;
}

.other-ops-nb {
    height: fit-content;
}

.MuiGrid-root .ops-icons {
    padding-left: 3px;
    padding-right: 0px;
    margin-right: -3px;
}

.MuiGrid-root .ops-tabs {
    width: 80px;
    height: 100%;
    margin-right: 10px;
    padding-top: 10px;
}

.icon-and-nb {
    display: flex;
    padding-left: 20px;
}

.ink-bar {
    height: 3px;
    border-radius: 3px 3px 0px 0px;
    width: 100%;
}

#ib-blue {
    background-color: #0854a0;
}

#ib-green {
    background-color: #107e3e;
}

#ib-orange {
    background-color: #df6e0c;
}

#ib-red {
    background-color: #bb0000;
}

.tab-label {
    padding-top: 6px;
    text-align: center;
    width: 100%;
}

.icon-blue {
    color: #0854a0;
}

.icon-green {
    color: #107e3e;
}

.icon-orange {
    color: #df6e0c;
}

.icon-red {
    color: #bb0000;
}

.icon-nb-label {
    width: 100%;
}

.MuiPaper-root {
    border-radius: 0px;
}

#audit-trail-toolbar-container {
    padding-top: 16px;
    padding-bottom: 4px;
    border-top: 1px solid #d9d9d9;
}

#audit-trail-toolbar-container span {
    font-weight: 600;
}

#audit-trail-toolbar-title {
    align-self: center;
    width: fit-content;
}

#audit-trail-toolbar-fields {
    align-items: center;
    height: fit-content;
    width: fit-content;
    justify-content: flex-end;
}

#audit-trail-toolbar-container .filter-fields {
    height: 36px;
    margin-left: 8px;
    border-radius: 1px;
    background-color: white;
}

#audit-trail-toolbar-container .MuiSvgIcon-root {
    color: #0854a0;
    margin-left: -5px;
}

.react-datepicker-popper {
    z-index: 3;
}

.invisible {
    visibility: hidden;
}

/* Table */

.status-print-validated {
    color: #107e3e;
}

.status-status-open {
    color: #df6e0c;
}

.status-status-aws-pdf-missing {
    color: #bb0000;
}

.status-print-initiated {
    color: #0f71d2;
}

table .table-header-cells {
    background-color: #f2f2f2;
}

#audit-trail-tablecontainer {
    max-height: 70vh;
}

table .table-bold-col {
    font-weight: bold;
}

table .table-wraptext {
    overflow-wrap: anywhere;
    min-width: 100px;
}

#audit-table-box .MuiBackdrop-root {
    position: absolute;
    padding-top: 100px;
}

#audit-table-box {
    position: relative;
}

#loading-message {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

#load-more-div {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

#dialog-linear-progress {
    width: 100%;
    justify-content: center;
    margin-bottom: 15px;
}

#audit-trail-no-data {
    width: 100%;
    justify-content: center;
    margin: 15px;
}

#reset-filter-btn {
    text-transform: none;
    margin-right: 15px;
}